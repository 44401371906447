.live-feedback-invalid-popup-wrapper {
    height: 100%;
    width: 475px;
    background: white;
    /* box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    border-radius: 10px;
}

.live-feedback-invalid-popup-content-wrapper{
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.live-feedback-invalid-popup-heading {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter';
    font-size: 22px;
    font-weight: 700;
    ;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: auto;
}

.live-feedback-invalid-popup-content{
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.please-try {
    text-decoration: underline;
}

.please-try-list {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin: auto;
}

.please-try-list-item {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter';
    font-size: 16px;
    font-weight: 400;
    line-height: 29.05px;
    ;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;
    gap: 10px;
    align-items: center;
}

.live-feedback-invalid-popup-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.live-feedback-invalid-popup-footer-text {
    font-family: -apple-system, BlinkMacSystemFont, 'Inter';
    font-size: 16px;
    font-weight: 700;
    line-height: 24.2px;
    ;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: auto;
}

.live-feedback-invalid-popup-footer-buttons{
    display: flex;
    justify-content: space-around;
}

.live-feedback-invalid-popup-footer-button{
width: 150px;
height: 40px;
border-radius: 5px;
background:#000000;
color:white;
font-size: 18px;
font-weight: 600;
}