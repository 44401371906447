.upload-text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.upload-title-upload{
    cursor: pointer;
    text-decoration: underline;
}

.upload-title-upload:hover{
    color: #007bff;
}

 .upload-boundary {
  border: 2px dotted rgba(253, 184, 40, 0.5);  /* Always visible border */
}

 .upload-boundary:hover,
 .upload-boundary:focus,
 .upload-boundary.drag-over {
  border: 2px dotted rgba(128, 128, 128, 0.5);  /* Grey border for special states */
}
